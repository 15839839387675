<!--
 * @Description: 创建协同单
 * @Author: ChenXueLin
 * @Date: 2021-08-23 11:38:01
 * @LastEditTime: 2022-10-12 15:59:59
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div class="content-view ">
      <section class="edit-wrapper">
        <div class="edit-wrapper__body">
          <!-- 基本信息 start -->
          <template>
            <div class="primaryTitle">创建协同单</div>
            <el-form
              label-width="140px"
              label-position="right"
              :model="addForm"
              :rules="addFormRules"
              ref="addForm"
            >
              <el-form-item label="协同单标题" prop="coordinationTitle">
                <el-input v-model="addForm.coordinationTitle"></el-input>
              </el-form-item>
              <el-form-item label="内容" prop="content">
                <el-input type="textarea" v-model="addForm.content"></el-input>
              </el-form-item>
              <el-form-item label="附件" prop="fileIds">
                <uploadOBS
                  class="upload-demo"
                  v-model="addForm.fileIds"
                  :fileType="10"
                  :limitSize="10"
                  multiple
                  :uploadFileList.sync="uploadFileList"
                ></uploadOBS>
              </el-form-item>
              <el-form-item label="关联单据" prop="invoiceId">
                <el-button type="primary" @click="bindBill"
                  >关联已有单据</el-button
                >
                <el-table
                  style="margin-top:15px;"
                  v-if="addForm.invoiceId"
                  border
                  :data="bindData"
                  highlight-current-row
                  ref="selectTable"
                >
                  <el-table-column
                    show-overflow-tooltip
                    v-for="(column, index) in columnData"
                    :key="index"
                    :prop="column.fieldName"
                    :label="column.fieldLabel"
                    :min-width="column.width"
                    :fixed="column.fixed"
                    :align="column.align"
                    header-align="center"
                  >
                  </el-table-column>
                </el-table>
              </el-form-item>
              <el-form-item label="指派给" prop="employeeId">
                <all-user-search
                  v-model="addForm.employeeId"
                  clear
                  placeholder="指派给"
                  title="指派给"
                  :propsConfig="{
                    id: 'userId',
                    label: 'employeeName'
                  }"
                >
                </all-user-search>
              </el-form-item>
            </el-form>
          </template>
        </div>
        <div class="edit-wrapper__footer">
          <el-button class="cancel" @click="handleGoBack">取消</el-button>
          <el-button type="primary" @click="handleSubmit">确定</el-button>
        </div>
      </section>
    </div>
    <!-- 关联单据弹框 -->
    <el-dialog
      v-dialogDrag
      title="关联单据"
      :visible="bindBillsDialog"
      width="800px"
      :close-on-click-modal="false"
      :element-loading-background="loadingBackground"
      :before-close="handleClose"
      custom-class="bind-bills-dialog"
    >
      <section class="table">
        <div class="search-box">
          <el-input placeholder="输入单据号查询" v-model="workNo"> </el-input>
          <el-button type="primary" @click="getOrder">查询</el-button>
        </div>
        <el-table
          border
          :data="tableData"
          highlight-current-row
          ref="selectTable"
          height="350px"
        >
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
          </el-table-column>
        </el-table>
      </section>

      <div class="dialog-footer" slot="footer">
        <el-button class="cancel" @click="handleClose">取消</el-button>
        <el-button type="primary" @click="handleConfirm">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import { bindBillsList, createTeamTask } from "@/api";
import { printError } from "@/utils/util";
import allUserSearch from "@/components/allUserSearch";
import uploadOBS from "@/components/uploadOBS/uploadOBS";
export default {
  name: "teamWorkAdd",
  data() {
    return {
      addForm: {
        coordinationTitle: "", //协同单标题
        content: "", //协同单内容
        invoiceId: "", //关联单据ID
        employeeId: "", //指派给
        fileIds: []
      },
      uploadFileList: [],
      addFormRules: {
        coordinationTitle: [
          {
            required: true,
            message: "请输入协同单标题",
            trigger: ["blur", "change"]
          }
        ],
        content: [
          {
            required: true,
            message: "请输入协同单内容",
            trigger: ["blur", "change"]
          }
        ],
        employeeId: [
          {
            required: true,
            message: "请选择指派人",
            trigger: ["blur", "change"]
          }
        ]
      },
      bindData: [], //关联的单据信息
      /******关联单据********/
      workNo: "", //输入的单据号
      bindBillsDialog: false, //是否显示关联单据弹框
      columnData: [
        {
          fieldName: "taskId",
          display: true,
          fieldLabel: "单据号",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "workType",
          display: true,
          fieldLabel: "单据类型",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "workStatus",
          display: true,
          fieldLabel: "单据状态",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "createdBy",
          display: true,
          fieldLabel: "创建人",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "createdStr",
          display: true,
          fieldLabel: "创建时间",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ],
      tableData: [],
      fileList: [],
      info: {}
    };
  },
  components: { allUserSearch, uploadOBS },
  computed: {},
  mixins: [base, listPage, listPageReszie],
  created() {},
  methods: {
    // 筛选文件类型和大小
    handleBeforeUpload(file) {
      if (file.size / 1024 / 1024 > 10) {
        this.$message.info("上传文件大小不能超过 10MB!");
        return false;
      }
      return true;
    },
    // 上传成功后的回调
    handleSuccess(res, file) {
      let {
        code,
        data: { id }
      } = res;
      console.log(res, "res---");
      if (code === "OK") {
        this.fileList.push({
          ...file,
          fileId: id
        });
      }
    },
    //删除图片
    handleRemove(file) {
      let idx = this.fileList.findIndex(item => {
        return item.uid === file.uid;
      });
      this.fileList.splice(idx, 1);
    },
    //确当新增
    handleSubmit() {
      this.$refs.addForm.validate(valid => {
        if (valid) {
          this.createTeamTask();
        }
      });
    },
    //创建协同单请求
    async createTeamTask() {
      try {
        // let fileIds = this.fileList.map(item => item.fileId);
        // fileIds
        let res = await createTeamTask({ ...this.addForm });
        if (res.code == "OK") {
          this.$message.success("创建成功");
          this.closeTag(this.$route);
          this.routerPush({
            name: "workOrderManage/teamWork",
            params: {
              refresh: true
            }
          });
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //取消新增
    handleGoBack() {
      this.closeTag(this.$route);
      this.routerPush({
        name: "workOrderManage/teamWork",
        params: {
          refresh: true
        }
      });
    },

    /******关联单据*********/
    //查询关联单据
    async getOrder() {
      try {
        let res = await bindBillsList({ workNo: this.workNo });
        let info = this.getFreezeResponse(res, {
          path: "data"
        });
        this.info = this.getFreezeResponse(res, {
          path: "data"
        });
        this.tableData = info.taskId ? [info] : [];
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //关联单据
    bindBill() {
      this.bindBillsDialog = true;
      // this.getOrder();
    },
    //确定绑定
    handleConfirm() {
      if (this.tableData.length) {
        this.addForm.invoiceId = this.tableData[0].taskId;
        this.bindData = _.cloneDeep(this.tableData);
      }
      this.tableData = [];
      this.workNo = "";
      this.bindBillsDialog = false;
    },
    //关闭弹框
    handleClose() {
      this.bindBillsDialog = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.edit-wrapper__body {
  .primaryTitle {
    margin-top: 15px;
    padding-left: 15px;
    box-sizing: border-box;
    font-size: 16px;
    font-weight: 600;
  }
  .avatar-uploader {
    /deep/ .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    /deep/.el-upload:hover {
      border-color: #409eff;
    }
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 180px;
    height: 150px;
    line-height: 150px;
    text-align: center;
  }
  .avatar {
    width: 180px;
    height: 150px;
    display: block;
  }
  .fileTitle {
    color: #a2a2a2;
    margin-bottom: 10px;
  }
  /deep/.el-form {
    .el-form-item {
      .e6-vr-select {
        width: 300px;
        .el-input {
          width: 230px;
        }
      }
      .el-input {
        width: 600px;
      }
      .el-textarea {
        width: 600px;
      }
      .upload-demo {
        width: 600px;
      }
    }
  }
}
.bind-bills-dialog {
  .table {
    .el-input {
      margin: 20px;
      width: 300px;
    }
  }
}
</style>
